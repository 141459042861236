import React from 'react';
import './Profile.css'; // Update the CSS file name

function Profile() {
  return (
    <div className="profile">
      <div className="profile-info">
        <h2>
            Mano
        </h2> 
        <p>We started The XChip to revolutionize the way people interact with technology.</p>  
        <h6>Mail: <a href="mailto:mano@xchip.in" className="email-link">mano@xchip.in</a></h6>
      </div>
    </div>
  );
}

export default Profile;
