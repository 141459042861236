import './App.css';
import Footer from './components/Footer';
import Profile from './components/Profile';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://public.xchip.in/logo/logo.png" className="App-logo" alt="logo" />
          <h3 className="App-h3">We Engineered the X Chip.</h3>
          <h3 className="App-h3">Tech for Everyone, Everywhere</h3> 
          <Profile /> 
      </header>
      <Footer />
    </div>
  );
}

export default App;
